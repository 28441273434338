import { FC } from 'react';
import { IBookingDetails } from '@common/interfaces';
import Box from '../Box';
import { formatToMoneyString } from '@common/utils';
import { StatementIcon } from '@assets/svg';
import css from './styles.module.scss';

interface IFinancialBlock {
  bookingDetails: IBookingDetails;
}

const FinancialBlock: FC<IFinancialBlock> = ({ bookingDetails }) => {
  return (
    <Box title='Financial' icon={<StatementIcon />}>
      <div className={css.content}>
        <div className={css.item}>
          <div className={css.label}>Total cost:</div>
          <div className={css.value}>{formatToMoneyString(23112)}</div>
        </div>
      </div>
    </Box>
  );
};

export default FinancialBlock;
