import { FC } from 'react';
import { useBanner, MaintenanceBanner } from '@unbooking/ui-kit';
import { Button, Main } from '@components';
import { StatementIcon, ViewBookingsIcon, MarkerIcon, WrenchCarIcon, WarningIcon, CheckInIcon } from '@assets/svg';
import { URLS } from '@common/constants';
import { CustomerAreaStatData } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useFacilityContext, useGlobalContext, useRepository } from '@context';
import css from './styles.module.scss';
import { Link } from 'react-router-dom';

const CustomerAreaEntryPage: FC = () => {
  const { customerRepository } = useRepository();
  const { facilityId } = useFacilityContext();
  const { user } = useGlobalContext();

  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_workshops',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_HBH_KEY),
  });

  const { data: stats, isLoading } = useQuery<CustomerAreaStatData>(
    ['stats', facilityId],
    () => customerRepository.getHomeStats(facilityId),
    {
      enabled: Boolean(facilityId),
    }
  );

  const dummyData = stats?.result || [];

  return (
    <Main loading={isLoading} withoutPaddings>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.title}>
            WFP <b>Workshop</b> Self Service Area
          </div>
          <div className={css.place}>
            <MarkerIcon />
            {user.organisation?.complete_name}
          </div>
          <div className={css.blocks}>
            <div className={css.block}>
              <div className={css.label}>Book maintenance</div>
              <div className={css.description}>Create new maintenance request.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  iconR={<ViewBookingsIcon />}
                  link={URLS.CUSTOMER_AREA_BOOK_MAINTENANCE}
                  text='Book maintenance'
                  variant='lochmara'
                />
              </div>
            </div>
            <div className={css.block}>
              <div className={css.label}>My maintenance requests</div>
              <div className={css.description}>Track your requests and perform actions.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  iconR={<WrenchCarIcon />}
                  link={URLS.CUSTOMER_AREA_BOOKINGS}
                  text='View maintenance requests'
                  variant='forest'
                />
              </div>
            </div>
            <div className={css.block}>
              <div className={css.label}>My invoices</div>
              <div className={css.description}>View your invoices or statements for your requests.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  iconR={<StatementIcon />}
                  link={URLS.CUSTOMER_AREA_INVOICES}
                  text='View invoices'
                  variant='kumera'
                />
              </div>
            </div>
          </div>
        </div>
        <div className={css.sidebar}>
          <div className={css.sidebarTitle}>
            <WarningIcon />
            Pending actions
          </div>
          <div className={css.infoBlock}>
            <div className={css.subtitle}>
              Check-in date to accept <CheckInIcon />
            </div>
            {dummyData.map(({ url, name, days }, idx) => {
              return (
                <div key={idx}>
                  <Link to={url}>{name}</Link>
                  {` - pending for ${days} day(s)`}
                </div>
              );
            })}
          </div>
          <div className={css.infoBlock}>
            <div className={css.subtitle}>
              New defects to review <WrenchCarIcon />
            </div>
            {dummyData.map(({ url, name, days }, idx) => {
              return (
                <div key={idx}>
                  <Link to={url}>{name}</Link>
                  {` - pending for ${days} day(s)`}
                </div>
              );
            })}
          </div>
          <div className={css.infoBlock}>
            <div className={css.subtitle}>
              PFI to approve <StatementIcon />
            </div>
            {dummyData.map(({ url, name, days }, idx) => {
              return (
                <div key={idx}>
                  <Link to={url}>{name}</Link>
                  {` - pending for ${days} day(s)`}
                </div>
              );
            })}
          </div>
          <div className={css.infoBlock}>
            <div className={css.subtitle}>
              Enter budget code <StatementIcon />
            </div>
            {[].map(({ url, name, days }, idx) => {
              return (
                <div key={idx}>
                  <Link to={url}>{name}</Link>
                  {` - pending for ${days} day(s)`}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default CustomerAreaEntryPage;
