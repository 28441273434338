import React, { FC, useState } from 'react';
import { useField } from 'informed';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { InformedFieldState } from '@common/interfaces';
import css from './styles.module.scss';

interface IPhoneField {
  name: string;
  preferredCountries?: Array<string>;
}

const PhoneField: FC<IPhoneField> = ({ name, preferredCountries }) => {
  const [, setValid] = useState(true);
  const [code, setCode] = useState('');

  const { fieldApi, fieldState, render, ref } = useField({ name });

  const { error, showError } = fieldState as InformedFieldState<string>;

  const fieldValue = ((fieldState.value as string) || '').replace(code, '').replace(/[ -]/g, '');
  const { setValue, setTouched } = fieldApi;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Input: any = IntlTelInput;

  return render(
    <div className={css.field}>
      <Input
        separateDialCode={true}
        ref={ref}
        value={fieldValue}
        preferredCountries={preferredCountries}
        onPhoneNumberChange={(
          isValid: boolean,
          value: string,
          { dialCode }: { dialCode: string },
          fullNumber: string
        ) => {
          setValid(isValid);
          if (value === '') {
            setValue('');
          } else {
            setValue(fullNumber);
          }
          setCode(`+${dialCode}`);
        }}
        onSelectFlag={() => {
          setValid(true);
          setValue('');
          setCode('');
        }}
        onPhoneNumberBlur={() => setTouched(true)}
        containerClassName='intl-tel-input'
        inputClassName={`intl-tel-element ${showError ? 'intl-tel-element-error' : ''}`}
      />
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default PhoneField;
