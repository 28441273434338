import { AxiosInstance } from 'axios';
import { axios } from '@common/utils';
import React, { createContext, FC, useContext, useMemo } from 'react';
import {
  AppointmentRepository,
  BookingRepository,
  CMSRepository,
  CustomerRepository,
  FacilityRepository,
  InvoiceRepository,
  JobcardRepository,
  MonolithAuthorizedRepository,
  MonolithRepository,
  PfiRepository,
  StatementRepository,
  GlobalRepository,
} from '@common/repositories';
import { useAuth } from '@unbooking/ui-auth';
import { useUserType } from '@common/hooks';
import { UserType } from '@common/interfaces';
import { apiNonSSOClientInstance } from '@common/clients';

const getRepositories = (axios: AxiosInstance) => ({
  appointmentRepository: new AppointmentRepository(axios),
  bookingRepository: new BookingRepository(axios),
  cmsRepository: new CMSRepository(axios),
  customerRepository: new CustomerRepository(axios),
  facilityRepository: new FacilityRepository(axios),
  invoiceRepository: new InvoiceRepository(axios),
  jobcardRepository: new JobcardRepository(axios),
  monolithRepository: new MonolithRepository(),
  monolithAuthorizedRepository: new MonolithAuthorizedRepository(axios),
  pfiRepository: new PfiRepository(axios),
  statementRepository: new StatementRepository(axios),
  globalRepository: new GlobalRepository(axios),
});

const RepositoryContext = createContext(getRepositories(axios));

interface IRepositoryProvider {
  children?: React.ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children }) => {
  const { axios: axiosSSOClient } = useAuth();
  const { userType } = useUserType();

  const axios = useMemo(
    () => (userType === UserType.NON_FEDERATED ? apiNonSSOClientInstance : axiosSSOClient),
    [userType, axiosSSOClient]
  );

  return <RepositoryContext.Provider value={getRepositories(axios)}>{children}</RepositoryContext.Provider>;
};

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
