import React, { FC } from 'react';
import css from './styles.module.scss';

interface ISwitcher {
  checked: boolean;
  label?: string;
  leftText?: string;
  onChange: (checked: boolean) => void;
  rightText?: string;
  disabled?: boolean;
  hideTexts?: boolean;
}

const Switcher: FC<ISwitcher> = ({
  checked,
  label,
  leftText = 'no',
  onChange,
  rightText = 'yes',
  disabled = false,
  hideTexts = false,
}) => {
  return (
    <label htmlFor='switcher' className={`${css.wrapper} ${disabled ? css.disabled : ''}`}>
      {label && <div className={css.label}>{label}</div>}
      <input
        checked={checked}
        className={css.input}
        id='switcher'
        onChange={(e) => onChange(e.target.checked)}
        type='checkbox'
      />
      <div className={`${css.switcher} ${checked ? css.active : ''}`}>
        {!hideTexts ? (
          checked ? (
            <div className={`${css.text} ${css.stRight}`}>{rightText}</div>
          ) : (
            <div className={`${css.text} ${css.stLeft}`}>{leftText}</div>
          )
        ) : null}
      </div>
    </label>
  );
};

export default Switcher;
