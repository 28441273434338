import { createContext, ReactNode, useContext } from 'react';
import { useRepository } from './repository.context';
import { useQuery } from 'react-query';
import { Loader } from '@components';
import { User, Waffles } from '@common/interfaces';

interface GlobalProviderProps {
  children: ReactNode;
}

type GlobalContextType = {
  waffles: Waffles;
  user: User;
};

const defaultFlag = { is_active: false, last_modified: '' };

const defaultGlobalContext: GlobalContextType = {
  waffles: {
    flags: {
      CUSTOMER_AREA: defaultFlag,
      MOBILE_RESPONSIVENESS: defaultFlag,
    },
    switches: {},
    samples: {},
  },
  user: {
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    full_name: '',
    is_active: true,
    organisation: null,
  },
};

const mergeContext = (serverContext: GlobalContextType) => {
  return {
    waffles: {
      flags: { ...defaultGlobalContext.waffles.flags, ...serverContext.waffles.flags },
      switches: { ...defaultGlobalContext.waffles.switches, ...serverContext.waffles.switches },
      samples: { ...defaultGlobalContext.waffles.samples, ...serverContext.waffles.samples },
    },
    user: {
      ...defaultGlobalContext.user,
      ...serverContext.user,
    },
  };
};

const GlobalContext = createContext<GlobalContextType>(defaultGlobalContext);

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const { globalRepository } = useRepository();

  const { data: user = defaultGlobalContext.user, isLoading: isUserLoading } = useQuery<User>('user', () =>
    globalRepository.getCurrentUser()
  );

  const { data: waffles = defaultGlobalContext.waffles, isLoading: isWafflesLoading } = useQuery<Waffles>(
    ['waffles'],
    () => globalRepository.getWaffles()
  );

  const value = mergeContext({ waffles, user });
  const loading = isWafflesLoading || isUserLoading;

  return (
    <GlobalContext.Provider value={value}>
      {loading ? <Loader full /> : null}
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
