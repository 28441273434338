import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ColumnType } from 'antd/lib/table/interface';
import {
  IDropdownOption,
  Nullable,
  PaginatedResponse,
  ICustomerBookingListTab,
  DateRange,
  SessionStorageKey,
  ICustomerBookingFilters,
  ICustomerBooking,
  ICustomerComment,
} from '@common/interfaces';
import { useFacilityContext, useRepository } from '@context';
import {
  StatusDropdown,
  Button,
  CheckboxDropdown,
  Main,
  MonthPicker,
  Page,
  SearchField,
  Table,
  TablePagination,
  Tabs,
  Tip,
  Switcher,
  KPIBlock,
} from '@components';
import {
  CheckInIcon,
  CheckOutIcon,
  CommentIcon,
  MarkerIcon,
  StatementIcon,
  WarningIcon,
  WrenchScrewIcon,
} from '@assets/svg';
import {
  DEFAULT_SERVER_DATE_FORMAT,
  DEFAULT_USER_DATE_FORMAT,
  DEFAULT_USER_DATE_TIME_FORMAT,
  LINKS,
} from '@common/constants';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import css from './styles.module.scss';

const tabList = [
  { name: 'To process', key: ICustomerBookingListTab.TO_PROCESS },
  { name: 'In progress', key: ICustomerBookingListTab.IN_PROGRESS },
  { name: 'Closed', key: ICustomerBookingListTab.CLOSED },
];

const DEFAULT_PAGE_SIZE = 10;

interface ICommentsCell {
  comments: ICustomerComment[];
}

const CommentsCell: FC<ICommentsCell> = ({ comments }) => {
  if (comments.length < 1) return <>-</>;

  return (
    <Tip
      content={
        <div className={css.tipContent}>
          {comments.map(({ body, created, created_by, uuid }) => (
            <div className={css.comment} key={uuid}>
              <div className={css.tipTitle}>
                {created_by.full_name}
                <div className={css.date}>{format(new Date(created), DEFAULT_USER_DATE_TIME_FORMAT)}</div>
              </div>
              {body}
            </div>
          ))}
        </div>
      }
    >
      <CommentIcon /> {comments.length}
    </Tip>
  );
};

interface IStatusCell {
  status: string;
}

const StatusCell: FC<IStatusCell> = ({ status }) => {
  // TO DO: Get this data from BE
  const actions = ['Confirm check-in date', 'Fix car issue'];

  return (
    <div className={css.statusCell}>
      <StatusDropdown status={status} allowedTransitions={[]} />
      {actions.length > 0 ? (
        <Tip
          content={
            <div className={css.tipContent}>
              <div className={css.tipTitle}>Action required</div>
              {actions.map((action, idx) => (
                <div key={idx}>{`- ${action}`}</div>
              ))}
            </div>
          }
        >
          <WarningIcon />
        </Tip>
      ) : null}
    </div>
  );
};

const columns: ColumnType<ICustomerBooking>[] = [
  {
    dataIndex: 'request_number',
    key: 'request_number',
    title: 'Request #',
    sorter: true,
  },
  {
    dataIndex: 'requestor',
    key: 'requestor',
    title: 'Requestor',
    render: (_, { requestor_name, requestor_surname }) => `${requestor_name} ${requestor_surname}`,
  },
  {
    dataIndex: 'requested',
    key: 'created',
    title: 'Request date',
    render: (_, { requested }) => format(new Date(requested), DEFAULT_USER_DATE_FORMAT),
    sorter: true,
  },
  {
    dataIndex: 'workshop',
    key: 'workshop',
    title: 'Workshop',
    sorter: true,
  },
  {
    dataIndex: 'jobcard',
    key: 'jobcard',
    title: 'Jobcard #',
    sorter: true,
  },
  {
    dataIndex: 'plate_number',
    key: 'plate_number',
    title: 'Plate/ID',
    sorter: true,
  },
  {
    dataIndex: 'asset',
    key: 'asset',
    title: 'Asset',
    sorter: true,
  },
  {
    dataIndex: 'comments',
    key: 'comments',
    title: 'Comments',
    render: (comments) => <CommentsCell comments={comments} />,
  },
  {
    dataIndex: 'state',
    key: 'state',
    title: 'Status',
    sorter: true,
    render: (_, { state }) => <StatusCell status={state} />,
  },
];

const CustomerAreaBookingListPage: FC = () => {
  const { facilityId, facility } = useFacilityContext();
  const { customerRepository } = useRepository();
  const navigate = useNavigate();
  const params = JSON.parse(sessionStorage.getItem(SessionStorageKey.CUSTOMER_BOOKING_LIST_PARAMS) || '{}') || {};

  const { city_name, country_name, name } = facility;

  const [tab, setTab] = useState<ICustomerBookingListTab>((params.tab as ICustomerBookingListTab) ?? tabList[0].key);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.pageSize || DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState(params.order || '');
  const [search, setSearch] = useState('');
  const [requestorFilter, setRequestorFilter] = useState<IDropdownOption[]>(params.requestorFilter || []);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption[]>(params.statusFilter || []);
  const [workshopFilter, setWorkshopFilter] = useState<IDropdownOption[]>(params.workshopFilter || []);
  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(params.dateFilter || null);
  const [pendingFilter, setPendingFilter] = useState(false);

  const { data: bookings, isLoading: isDataLoading } = useQuery<PaginatedResponse<ICustomerBooking>>(
    ['bookings', tab, order, page, pageSize, requestorFilter, statusFilter, workshopFilter, dateFilter, search],
    () =>
      customerRepository.getBookings(facilityId, {
        limit: pageSize,
        offset: (page - 1) * 10,
        ordering: order,
        search,
        requestor: requestorFilter.map(({ value }) => value).join(','),
        state: statusFilter.map(({ value }) => value).join(','),
        workshop: workshopFilter.map(({ value }) => value).join(','),
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        tab_type: tab,
      })
  );

  const { data: filtersData, isLoading: isFiltersLoading } = useQuery<ICustomerBookingFilters>(
    ['booking-filters', tab],
    () =>
      customerRepository.getFilters(facilityId, {
        tab_type: tab,
      })
  );

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setOrder('');
  };

  const clearFilters = () => {
    setRequestorFilter([]);
    setStatusFilter([]);
    setWorkshopFilter([]);
    setDateFilter(null);
    setPendingFilter(false);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
  };

  const tabChange = (tab: string) => {
    setTab(tab as ICustomerBookingListTab);
    clearAllParams();
  };

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKey.CUSTOMER_BOOKING_LIST_PARAMS,
      JSON.stringify({
        tab,
        order,
        page,
        pageSize,
        requestorFilter,
        statusFilter,
        workshopFilter,
        dateFilter,
      })
    );
  }, [tab, order, page, pageSize, requestorFilter, statusFilter, workshopFilter, dateFilter]);

  const place = `${city_name}, ${country_name}`;
  const loading = isDataLoading || isFiltersLoading;
  const isFiltered =
    requestorFilter.length || workshopFilter.length || statusFilter.length || dateFilter || pendingFilter;

  const results = bookings?.results || [];

  // TO DO: Need real data
  const kpiData = results
    ? [
        {
          label: 'PFIs to confirm',
          value: 0,
          icon: <StatementIcon />,
          color: 'lochmara',
        },
        {
          label: 'Check-in date to approve',
          value: 16,
          icon: <CheckInIcon />,
          color: 'sycamore',
        },
        {
          label: 'Defects to review',
          value: 1,
          icon: <WrenchScrewIcon />,
          color: 'bluch',
        },
        {
          label: 'Asset ready for pick-up',
          value: 1,
          icon: <CheckOutIcon />,
          color: 'jewel',
        },
      ]
    : null;

  return (
    <Main>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>My maintenance requests</div>
            <div className={css.info}>
              <div>
                <MarkerIcon />
                {place}
              </div>
            </div>
          </div>
          <KPIBlock items={kpiData} />
        </div>
        <Tabs tabList={tabList} tabKey={tab} onTabChange={tabChange} />
        <section className={css.tab}>
          <div className={css.topPanel}>
            <div className={css.filters}>
              <span>Filters:</span>
              <CheckboxDropdown
                items={filtersData?.requestors}
                onChange={(options) => {
                  clearPagination();
                  setRequestorFilter(options);
                }}
                selectedItems={requestorFilter}
                title='Requestor'
              />
              <MonthPicker
                showDates
                initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
                initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
                setMonths={(from, to) => {
                  if (from && to) {
                    setDateFilter({
                      created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                      created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                    });
                  }
                }}
              />
              <CheckboxDropdown
                items={filtersData?.workshops}
                onChange={(options) => {
                  clearPagination();
                  setWorkshopFilter(options);
                }}
                selectedItems={workshopFilter}
                title='Type'
              />
              <CheckboxDropdown
                items={filtersData?.states}
                onChange={(options) => {
                  clearPagination();
                  setStatusFilter(options);
                }}
                selectedItems={statusFilter}
                title='Status'
              />
              {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
            </div>
            <div className={css.filters}>
              <Switcher label='Show pending actions' hideTexts checked={pendingFilter} onChange={setPendingFilter} />
              <SearchField onChange={setSearch} value={search} placeholder='Search booking...' />
            </div>
          </div>
          <Table
            className={css.table}
            columns={columns}
            data={results}
            loading={loading}
            onChangeColumnOrder={setOrder}
            onRowClick={({ uuid }) => navigate(LINKS.customerBooking(uuid))}
            // TO DO: Need logic
            rowClassName={(record) => css.pendingRow}
          />
          <TablePagination
            page={page}
            onChangePage={setPage}
            totalCount={bookings?.count}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
          />
        </section>
      </Page>
    </Main>
  );
};

export default CustomerAreaBookingListPage;
