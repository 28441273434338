import { Nullable } from '@common/interfaces';
import css from './styles.module.scss';
import { FC } from 'react';

interface KPIItem {
  value: number;
  icon: JSX.Element;
  label: string;
  color: string;
}

interface IKPIBlock {
  items: Nullable<KPIItem[]>;
}

const KPIBlock: FC<IKPIBlock> = ({ items }) => {
  if (!items) return null;
  return (
    <div className={css.stat}>
      {items.map(({ icon, label, value, color }, idx) => (
        <div className={css.count} key={idx}>
          <div className={css.value}>
            <div className={`${css.iconWrapper} ${css[color]}`}>{icon}</div> {value}
          </div>
          <div className={css.label}>{label}</div>
        </div>
      ))}
    </div>
  );
};

export default KPIBlock;
