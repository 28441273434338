export enum UserType {
  SSO = 'sso',
  NON_FEDERATED = 'non-federated',
  UNKNOWN = 'unknown',
}

export enum QueryParams {
  IS_NON_FEDERATED_USER = 'is_non_federated_user',
  FACILITY_ID = 'facility_id',
  TAB = 'tab',
}

export enum LocalStorageKey {
  ID_TOKEN = 'ID_TOKEN',
  USER_EMAIL = 'USER_EMAIL',
  USER_NAME = 'USER_NAME',
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  FIRST_LOGIN_ATTEMPT = 'FIRST_LOGIN_ATTEMPT',
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  USER_TYPE = 'USER_TYPE',
  X_USER_TOKEN = 'X_USER_TOKEN',
  FACILITY_ID_MONOLITH = 'FACILITY_ID_MONOLITH',
}

export enum SessionStorageKey {
  BOOKING_LIST_PARAMS = 'bookingListParams',
  STATEMENT_LIST_PARAMS = 'statementListParams',
  CUSTOMER_BOOKING_LIST_PARAMS = 'customerBookingListParams',
}

export enum JobcardStatus {
  WAITING_FOR_PARTS = 'Waiting for parts',
  WORK_IN_PROGRESS = 'Work in progress',
  CLOSED = 'Closed',
}

export type Nullable<T> = T | null;

export interface DataItem {
  label: string;
  value: string;
}

export interface ValueItem {
  label: string;
  value: Nullable<number>;
}

export interface ValueItemColorized extends ValueItem {
  backgroundColor?: string;
}

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface PaginatedResponse<Item> {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: Item[];
}

export interface DefaultListParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

export interface InformedFieldState<Value> {
  error: string;
  showError: boolean;
  value: Value;
}

export interface DateRange {
  created_before?: string;
  created_after?: string;
}
