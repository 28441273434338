import { AppType, URLS } from '@common/constants';

const useAppType = () => {
  let appType = null;

  if (location.pathname.includes(URLS.DO)) {
    appType = AppType.DIGITAL_OFFICE;
  } else if (location.pathname.includes(URLS.CUSTOMER_AREA)) {
    appType = AppType.CUSTOMER_AREA;
  } else {
    appType = AppType.DEFAULT;
  }

  return appType;
};

export default useAppType;
