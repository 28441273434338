import { FC, useEffect } from 'react';
import { Main, StreamField } from '@components';
import { useRepository } from '@context';
import { useQuery } from 'react-query';
import { CMSPageResponse } from '@common/interfaces';
import { MaintenanceBanner, useBanner } from '@unbooking/ui-kit';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppType, URLS } from '@common/constants';
import css from './styles.module.scss';
import { useAppType } from '@common/hooks';

const CMSPage: FC = () => {
  const { cmsRepository } = useRepository();
  const navigate = useNavigate();
  const location = useLocation();
  const appType = useAppType();

  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_workshops',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_HBH_KEY),
  });

  const slug = location.pathname.replace(/\//g, '');
  const isDefault = appType === AppType.DEFAULT;

  const { data, isLoading } = useQuery<CMSPageResponse>(`cms-site-${slug}`, () => cmsRepository.getElement(slug), {
    onError: () => navigate(URLS.NOT_FOUND, { state: { appType } }),
    enabled: isDefault,
  });

  useEffect(() => {
    // TO DO: Fix this issue
    // if (!isDefault) navigate(URLS.NOT_FOUND, { state: { appType } });
    // OLD: if (isDO) navigate(URLS.NOT_FOUND, { state: { isDO } });
  }, []);

  return (
    <Main background='none' loading={isLoading} withoutPaddings>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      {data && typeof data === 'object' ? (
        <div
          key={data.id}
          style={{
            background: `linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url(${data.background?.meta.download_url})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
          }}
          className={css.streamContainer}
        >
          <StreamField data={data.content} />
        </div>
      ) : null}
    </Main>
  );
};

export default CMSPage;
