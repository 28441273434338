import { DefaultListParams, IFocalPoint, IJobcard } from '@common/interfaces';
import { DataItem, Nullable } from '@common/interfaces';

export enum ICustomerBookingStatus {
  // To process
  NOT_PROCESSED = 'not_processed', // auto - when booking created
  ACCEPTED = 'accepted', // auto - after email sent to client
  CHECK_IN_CONFIRMED_BY_CLIENT = 'check_in_confirmed_by_client', // manual - after client confirmed
  ON_HOLD = 'on_hold', // manual - selected when status is "To be processed".
  CHECK_IN_SCHEDULED = 'check_in_scheduled', // unknown
  NEW_CHECK_IN_DATE_REQUESTED = 'new_check_in_date_requested', // unknown

  // In progress
  INSPECTED = 'inspected', // manual - after inspection done
  PFI_IN_APPROVAL = 'pfi_in_approval', // manual – when they circulate the PFI to collect internal approval.
  PFI_SENT = 'pfi_sent', // manual – when they send PFI by email to client.
  SIGNED_PFI_RECEIVED = 'signed_pfi_received', // automatic - when user clicks on Confirm PFI approval and Upload signed PFI
  WORK_IN_PROGRESS = 'in_progress', // manual

  // Closed
  REJECTED = 'rejected', // auto - after rejection of booking
  WORK_COMPLETED = 'work_completed', // auto - when Jobcard is closed on FMS
  READY_FOR_PICK_UP = 'ready_for_pick_up', // auto - after check-out scheduled
  HANDOVER_DONE = 'handover_done', // manual
  INVOICE_SENT = 'invoice_sent', // manual
  INVOICE_PAID = 'invoice_paid', // manual
  COMPLETED = 'completed', // manual
  CANCELLED = 'cancelled_by_fp', // manual
}

export enum ICustomerBookingListTab {
  TO_PROCESS = 'TO_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface CustomerAreaStatData {
  result: { url: string; name: string; days: number }[];
}

export interface ICustomerBookingListParams extends DefaultListParams {
  created_after?: string;
  created_before?: string;
  requestor?: string;
  show?: string;
  state?: string;
  tab_type?: string;
  workshop?: string;
}

export interface ICustomerBookingFiltersParams {
  tab_type: ICustomerBookingListTab;
}

export interface ICustomerBookingFilters {
  states: DataItem[];
  requestors: DataItem[];
  workshops: DataItem[];
}

export interface ICustomerBooking {
  allowed_transitions: DataItem[];
  current_odometer_value: number;
  facility_id_on_hbh: number;
  has_spare_parts: boolean;
  is_quotation_required: boolean;
  make: Nullable<string>;
  model: Nullable<string>;
  odometer_metric: string;
  plate_number: string;
  reference_code: string;
  remarks: string;
  request_number: string;
  requested: string;
  requesting_agency: string;
  requestor_name: string;
  requestor_surname: string;
  state: ICustomerBookingStatus;
  type_of_asset: string;
  uuid: string;

  // New fields
  comments: ICustomerComment[];
}

// Customer Booking Details
// export interface ICommentDraft {
//   body: string;
// }

// export interface IDefaultComment {
//   body: string;
//   author: string;
//   uuid: string;
//   label: string;
// }

export interface ICustomerComment {
  body: string;
  created: string;
  created_by: IFocalPoint;
  updated: string;
  uuid: string;
}

export interface ICustomerBookingDetails {
  agency_number: Nullable<string>;
  allowed_transitions: DataItem[];
  asset_fms_customer: Nullable<string>;
  current_odometer_value: number;
  department_wfp: string;
  fms_request_sent: Nullable<boolean>;
  fms_jobcard_link_request_sent: Nullable<boolean>;
  fms_sync_in_progress: Nullable<boolean>;
  has_spare_parts: boolean;
  is_quotation_required: boolean;
  jobcard: Nullable<IJobcard>;
  odometer_metric: string;
  pfi: Nullable<string>;
  pdf: Nullable<string>;
  plate_number: string;
  reference_code: string;
  rejection_remarks: string;
  remarks: string;
  request_number: string;
  requested: string;
  requesting_agency: string;
  state: ICustomerBookingStatus;
  uuid: string;
  year_of_asset: number;
  job_reason: string;
}
