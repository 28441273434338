import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { ICustomerRepository, ICustomerBookingListParams, ICustomerBookingFiltersParams } from '@common/interfaces';

class CustomerRepository extends AxiosRepository implements ICustomerRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getBookings(facilityId: string, params?: ICustomerBookingListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/`, { params });

    // TO DO
    // return data;

    return {
      count: 1,
      next: null,
      previous: null,
      results: [
        {
          uuid: '8d08795f-3ab4-4598-8fa5-ac8ad064b8e8',
          requested: '2024-05-23',
          facility_id_on_hbh: 5605,
          requestor_name: 'Marie',
          requestor_surname: 'GOURAUD',
          allowed_transitions: [],
          odometer_metric: 'Hours',
          reference_code: 'AC4477',
          request_number: 'WS24-022-UU',
          requesting_agency: 'WFP',
          type_of_asset: 'Light vehicle',
          department_wfp: 'NON WFP',
          remarks: '',
          has_spare_parts: false,
          is_quotation_required: false,
          plate_number: '0ED70',
          current_odometer_value: 23,
          rejection_remarks: '',
          job_reason: '08',
          fms_request_sent: true,
          fms_jobcard_link_request_sent: null,
          fms_sync_in_progress: null,
          defects_rejection_remarks: '',
          state: 'in_progress',
          organisation: 'f12e0d4d-47dd-42a1-be0a-b1a6b562f6f1',
          make: '2221aba1-c250-4a83-ab15-0a72cb49fe63',
          model: 'c4b53b02-0a67-4c70-a43d-103c532ff54e',
          body_type: null,
          comments: [],
        } as any,
        {
          uuid: '8d08795f-3ab4-4598-8fa5-ac8ad064b8e8',
          requested: '2024-05-23',
          facility_id_on_hbh: 5605,
          requestor_name: 'Marie',
          requestor_surname: 'GOURAUD',
          allowed_transitions: [
            {
              label: 'Cancelled by FP',
              value: 'cancelled_by_fp',
            },
          ],
          odometer_metric: 'Hours',
          reference_code: 'AC4477',
          request_number: 'WS24-067-SS',
          requesting_agency: 'WFP',
          type_of_asset: 'Generator',
          department_wfp: 'NON WFP',
          remarks: '',
          has_spare_parts: false,
          is_quotation_required: false,
          plate_number: '00481770',
          current_odometer_value: 5678,
          rejection_remarks: '',
          job_reason: '08',
          fms_request_sent: true,
          fms_jobcard_link_request_sent: null,
          fms_sync_in_progress: null,
          defects_rejection_remarks: '',
          state: 'in_progress',
          organisation: 'f12e0d4d-47dd-42a1-be0a-b1a6b562f6f1',
          make: '2221aba1-c250-4a83-ab15-0a72cb49fe63',
          model: 'c4b53b02-0a67-4c70-a43d-103c532ff54e',
          body_type: null,

          comments: [
            {
              uuid: '2890c82d-723d-4b1b-b51f-6796d8d91bdb',
              created_by: {
                uuid: '019c2f92-20b7-409f-aa7b-367bb8d55a60',
                email: 'ivan.bobyr@wfp.org',
                first_name: 'Ivan',
                last_name: 'Bobyr',
                full_name: 'Ivan Bobyr',
                is_active: true,
              },
              created: '2025-01-07T18:51:11.406882Z',
              updated: '2025-01-07T18:51:11.406896Z',
              body: 'Lorem reefer efrer ferfe er ferfe rverf erfer erfr eferv ispum wedwe dwedwed wedddew',
            },
            {
              uuid: '2890c82d-723d-4b1b-b51f-6796d8d91bdb',
              created_by: {
                uuid: '019c2f92-20b7-409f-aa7b-367bb8d55a60',
                email: 'ivan.bobyr@wfp.org',
                first_name: 'Ivan',
                last_name: 'Bobyr',
                full_name: 'Ivan Bobyr',
                is_active: true,
              },
              created: '2025-01-07T18:51:11.406882Z',
              updated: '2025-01-07T18:51:11.406896Z',
              body: 'Lorem ispum wedwedwedwedwedddew',
            },
          ],
        } as any,
      ],
    };
  }

  async getBookingDetails(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/`);
    return data;
  }

  async getFilters(facilityId: string, params?: ICustomerBookingFiltersParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/filters/`, { params });
    return data;
  }

  // async getComments(facilityId: string, bookingId: string) {
  //   const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/comments/`, {
  //     params: { limit: 300 },
  //   });
  //   return data;
  // }

  // async sendComment(facilityId: string, bookingId: string, comment: ICommentDraft) {
  //   const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/comments/`, comment);
  //   return data;
  // }

  async getHomeStats(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/key-numbers/`);
    // TO DO
    // return data;
    return {
      result: [
        { url: 'www.lorem', name: 'LOR1234-IPS', days: 5 },
        { url: 'www.lorem', name: 'LOR12343234234-IPS', days: 1768 },
        { url: 'www.lorem', name: 'LOR1234-IPS', days: 1 },
        { url: 'www.lorem', name: 'LOR1234-IPS', days: 123 },
        { url: 'www.lorem', name: 'LOR1234-IPS', days: 123 },
      ],
    };
  }
}

export default CustomerRepository;
