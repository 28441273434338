import { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { StatusDropdown, Button, Chat, Main, Page } from '@components';
import { ArrowLeftIcon, CheckInIcon, CheckOutIcon, CommentIcon } from '@assets/svg';
import {
  IBookingDetails,
  IBookingDetailsPostData,
  IComment,
  ICommentDraft,
  IFocalPoint,
  PaginatedResponse,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { useParams } from 'react-router-dom';
import { ActionsBlock, DetailsBlock, FinancialBlock, StatusHistoryModal } from './components';
import { toast } from 'react-toastify';
import Box from './components/Box';

import css from './styles.module.scss';
import { URLS } from '@common/constants';

const CustomerAreaBookingDetailsPage: FC = () => {
  const { id: bookingId = '' } = useParams();
  const { bookingRepository, facilityRepository } = useRepository();
  const { facilityId } = useFacilityContext();

  const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState(false);

  const {
    data: bookingDetails,
    isLoading: isDetailsLoading,
    refetch,
  } = useQuery<IBookingDetails>(`request-${bookingId}`, () =>
    bookingRepository.getBookingDetails(facilityId, bookingId)
  );

  const { data: focalPoints, isLoading: isFocalPointsLoading } = useQuery<PaginatedResponse<IFocalPoint>>(
    'focal-points',
    () => facilityRepository.getFocalPoints(facilityId)
  );

  const {
    data: comments,
    isLoading: isCommentsLoading,
    refetch: refetchComments,
  } = useQuery<PaginatedResponse<IComment>>('comments', () => bookingRepository.getComments(facilityId, bookingId));

  const { mutate: sendComment, isLoading: isSendingCommentInProgress } = useMutation(
    (data: ICommentDraft) => bookingRepository.sendComment(facilityId, bookingId, data),
    {
      onSuccess: () => {
        toast.success('Comment was added successfully');
        refetchComments();
      },
    }
  );

  const { mutate: updateBooking, isLoading: isUpdatingInProgress } = useMutation(
    (data: IBookingDetailsPostData) => bookingRepository.updateBooking(facilityId, bookingId, data),
    {
      onSuccess: (booking) => {
        toast.success(`Booking ${booking.request_number} was updated`);
        refetch();
      },
    }
  );

  const isLoading =
    isCommentsLoading || isFocalPointsLoading || isDetailsLoading || isSendingCommentInProgress || isUpdatingInProgress;

  return (
    <Main loading={isLoading}>
      <Page>
        {bookingDetails ? (
          <>
            <Button
              text='Back to requests'
              variant='text'
              iconL={<ArrowLeftIcon />}
              link={URLS.CUSTOMER_AREA_BOOKINGS}
            />
            <div className={css.top}>
              <div>
                <div className={css.titleLine}>
                  <div className={css.title}>
                    Request <div className={css.number}>{bookingDetails.request_number}</div>
                  </div>
                  <StatusDropdown
                    allowedTransitions={bookingDetails.allowed_transitions}
                    status={bookingDetails.state}
                    updateBooking={updateBooking}
                    bookingDetails={bookingDetails}
                    requestor={`${bookingDetails.requestor_details.name} ${bookingDetails.requestor_details.surname}`}
                  />
                  {bookingDetails.transitions.length > 0 ? (
                    <Button text='View change history' variant='text' onClick={() => setStatusHistoryModalOpen(true)} />
                  ) : null}
                </div>
                <div className={css.info}>
                  <div>
                    <CheckInIcon />
                    Check-in:
                    {`---`}
                  </div>
                  <div>
                    <CheckOutIcon />
                    Check-out:
                    {`---`}
                  </div>
                </div>
              </div>
              <ActionsBlock bookingDetails={bookingDetails} refetch={refetch} updateBooking={updateBooking} />
            </div>
            <section className={css.container}>
              <DetailsBlock bookingDetails={bookingDetails} updateBooking={updateBooking} />
              <div className={css.boxWrapper}>
                <Box title='Comments' icon={<CommentIcon />}>
                  <Chat
                    defaultComments={
                      bookingDetails.jobcard?.remarks
                        ? [{ body: bookingDetails.jobcard.remarks, author: 'FMS Remark', uuid: uuid(), label: 'FMS' }]
                        : undefined
                    }
                    comments={comments?.results || []}
                    focalPoints={focalPoints?.results}
                    sendComment={(comment: string) => sendComment({ body: comment })}
                  />
                </Box>
                <FinancialBlock bookingDetails={bookingDetails} />
              </div>
            </section>
          </>
        ) : null}
        {statusHistoryModalOpen && bookingDetails && (
          <StatusHistoryModal
            transitions={bookingDetails.transitions}
            closeModal={() => setStatusHistoryModalOpen(false)}
          />
        )}
      </Page>
    </Main>
  );
};

export default CustomerAreaBookingDetailsPage;
